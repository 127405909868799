import React from 'react';

export default function Error() {

  return(
    <div>
      <h1>Página de Erro</h1>
      <p>404</p>
    </div>
  )
}